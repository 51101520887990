export const API_MOUNT_PATH = "/api/V9";
export const POLLING_URL = "https://internet.code.amalgamrx.com";
export const glucoseUomEnum = {
  mgdl: 1,
  mmol: 2,
};
export const WeightUOMEnum = {
  Kilograms: 1,
  Pounds: 2,
};
export const MeterOptions = (props) => [
  { value: 2, label: props.t("common.ACCU_CHECK_METER") },
  { value: 3, label: props.t("common.CONTOUR_NEXT_METER") },
  { value: 4, label: props.t("common.ONE_TOUCH_METER") },
  { value: 5, label: props.t("common.ACCU_CHECK_METER_GUIDE_ME") },
  { value: 6, label: props.t("common.ACCU_CHECK_METER_INSTANT") },
  { value: 7, label: props.t("common.CONTOUR_CARE_METER") },
  { value: 8, label: props.t("common.CONTOUR_NEXT_METER_NEW") },
  { value: 9, label: props.t("common.ACCU_CHEK_MOBILE") },
  { value: 10, label: props.t("common.ONETOUCH_ULTRA_PLUS_FLEX") },
  { value: 11, label: props.t("common.ONETOUCH_SELECT_PLUS_FLEX") },
];
export const InsulinTypeOptions = (props) => [
  { value: 1, label: props.t("common.MALLYA_PEN_CAP_METER") },
  { value: 2, label: props.t("common.NOVO_PEN_CAP_METER") },
  { value: 3, label: props.t("common.ECHO_PLUS_PEN_CAP_METER") },
];
export const CRMMeterOptions = (props) => [
  { value: 101, label: props.t("common.FREESTYLELIBRE") },
];
export const bgUOMOptions = (props) => [
  { label: props.t("commonCountrySpecific.COMMON__MG_DL"), value: 1 },
  { label: props.t("commonCountrySpecific.COMMON__MMOL_L"), value: 2 },
];
