const color = {
  hoverBGLightBlue: "#b6c7ea",
  switchBGGrey: "#BDBDBD",
  iconBlue: "#2147A4",
  textDarkGrey: "#515974",
  bgLightGrey: "#F9FAFB",
  tooptipIconGreen: "#2E7D32",
  alertLightOrange: "#5F2B01",
  circularColor: "rgba(0, 0, 0, 0.38)",
  eyeIconColor: "#535A6E",
  errorBGColor: "#FBEAEA",
  errorTextColor: "#D32F2F",
  eyeIconColor1: "#0000008F",
  lineChart: "#383D4C61",
  BBG: "#EAECF0",
  borderC: "#eaeaea",
  sideEffectColor: "rgba(212, 148, 13, 1)",
  severityColor: "#CD2F2C",
  severityBarColor:
    "linear-gradient(0deg, #FFECEC 0%, #FFC8C7 26.88%, #FFA3A1 53.76%, #FF7E7C 80.64%, #FF6360 107.51%)",
  A1CGraphColor: "rgba(212, 13, 118, 1)",
  WeightGraphColor: "rgba(10, 114, 164, 1)",
};
export default color;
