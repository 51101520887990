import React, { Suspense } from "react";
import {
  defaultUserObj,
  UserLoggedInContextProvider,
} from "./ContextAndHOC/LoggedInContext";
import refreshTokens from "./ContextAndHOC/RefreshTokens";
import PropTypes from "prop-types";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import "./index.css";
import themeCalculator from "./ThemeFile";
import Analytics from "@aws-amplify/analytics";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Spinner from "@mui/material/CircularProgress";
import PublicPage from "./views/Public/PublicPage";
import UnableToLogin from "./views/Public/UnableToLogin";
import "./i18nTranslation";
import { Helmet } from "react-helmet";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import axios from "axios";
import TimeOutBackground from "./components/TimeOutBackground";
import countDownTimer from "./components/timeout";
import FormReloader from "./components/formReloader";
import { API_MOUNT_PATH } from "./constants";
// import AuthSSO from "./views/Public/AuthSSO";

const SuperAdmin = React.lazy(() =>
  import(/*webpackChunkName: 'SuperAdmin' */ "./views/SuperAdmin/SuperAdmin")
);
const Provider = React.lazy(() =>
  import(/*webpackChunkName: 'Provider' */ "./views/Provider/Provider")
);
const AccountAdmin = React.lazy(() =>
  import(
    /*webpackChunkName: 'AccountAdmin' */ "./views/AccountAdmin/AccountAdmin"
  )
);
const ClaimAdmin = React.lazy(() =>
  import(/*webpackChunkName: 'AccountAdmin' */ "./views/ClaimAdmin/ClaimAdmin")
);
const CoWorker = React.lazy(() =>
  import(/*webpackChunkName: 'CoWorker' */ "./views/CoWorker/CoWorker")
);
const PtPrintDetails = React.lazy(() =>
  import(/*webpackChunkName: 'NovoPrint' */ "./views/Print/pt_PrintDetails")
);
const ReportsDetails = React.lazy(() =>
  import(
    /*webpackChunkName: 'NovoPrint' */ "./views/Reports/SummaryReports.jsx"
  )
);
const FirstTimeLogin = React.lazy(() =>
  import(/*webpackChunkName: 'CoWorker' */ "./views/Public/FirstTimeLogin")
);
const SignUp = React.lazy(() =>
  import(/*webpackChunkName: 'CoWorker' */ "./views/Public/SignUpPage")
);
const Loader = React.lazy(() =>
  import(/*webpackChunkName: 'DefaultPrint' */ "./views/Avhana/Loader")
);
const LoaderForLogbook = React.lazy(() =>
  import(
    /*webpackChunkName: 'DefaultPrint' */ "./views/Avhana/LoaderForLogbook"
  )
);
const SelectPreferences = React.lazy(() =>
  import(/*webpackChunkName: 'CoWorker' */ "./views/Public/SelectPreferences")
);

const UpdateDefaultConfig = React.lazy(() =>
  import(
    /*webpackChunkName : 'SuperAdmin' */ "./views/SuperAdmin/UpdateDefaultConfig"
  )
);
const AuthSSO = React.lazy(() => import("./views/Partner/AuthSSO"));

const cacheRtl = createCache({
  key: "mui-style-rtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
  key: "mui-style-ltr",
});

class App extends React.Component {
  constructor(props) {
    super(props);
    let lsUserAuth = localStorage.getItem("UserAuth");
    try {
      lsUserAuth = JSON.parse(lsUserAuth);
    } catch (error) {
      lsUserAuth = null;
    }
    this.state = {
      userDetails: lsUserAuth ? lsUserAuth : defaultUserObj,
      isVerified: true,
      setIsVerified: this.setIsVerified,
      userGuideName: props._config.userGuideName,
      userGuideNameForHCP: props._config.userGuideNameForHCP,
      userGuideNameForAdmin: props._config.userGuideNameForAdmin,
      changeUser: this.changeUser,
      refreshTokens: refreshTokens(),
      userTimer: countDownTimer(),
      environment: props._config.environment,
    };
  }
  componentDidMount = async () => {
    try {
      await this.state.refreshTokens();
      let getUserDetails = await axios({
        method: "get",
        url: API_MOUNT_PATH + "/providerservice/getUserDetails",
        headers: { "x-access-token": localStorage.getItem("accessToken") },
      });
      if (getUserDetails.status === 200) {
        getUserDetails.data.isAuthenticated = true;
        let observationType;
        if (getUserDetails.data.observationType !== undefined) {
          for (let i = 0; i < getUserDetails.data.observationType.length; i++) {
            if (getUserDetails.data.observationType[i].type === 3) {
              observationType = getUserDetails.data.observationType[i].uom;
            }
          }
        }
        getUserDetails.data.glucoseUnit =
          observationType !== undefined && observationType[0] === 1
            ? this.props.t("commonCountrySpecific.COMMON__MG_DL")
            : this.props.t("commonCountrySpecific.COMMON__MMOL_L");
        getUserDetails.data.glucoseUom =
          observationType !== undefined && observationType[0];
        this.changeUser(getUserDetails.data);
      }
    } catch (error) {
      //ignoring error related to authentication
    }
  };
  changeUser = async (userObj) => {
    if (userObj) {
      localStorage.setItem("UserAuth", JSON.stringify(userObj));
      await Analytics.updateEndpoint({
        userAttributes: {
          role: [userObj.role],
          uID: [userObj.userid],
        },
      });
      this.setState({ userDetails: userObj });
    } else {
      localStorage.setItem("UserAuth", JSON.stringify(defaultUserObj));
      this.setState({ userDetails: defaultUserObj });
      Analytics.updateEndpoint({
        userAttributes: {
          role: [],
          uID: ["0"],
        },
      });
    }
  };

  setIsVerified = (isVerified) => {
    this.setState({ isVerified });
  };

  render() {
    const isRtl = this.props._config.RTLJustification;
    return (
      <div dir={isRtl ? "rtl" : "ltr"}>
        <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{this.props._config.title}</title>
            <link
              rel="icon"
              type="image/png"
              href={`${process.env.PUBLIC_URL}/favicons/${this.props._config.favicon}`}
            />
            <link
              rel="apple-touch-icon"
              type="image/png"
              href={`${process.env.PUBLIC_URL}"/favicons/"${this.props._config.appleImage}`}
            />
            <link
              rel="manifest"
              href={`${process.env.PUBLIC_URL}${this.props._config.manifest}`}
            />
          </Helmet>
          <ThemeProvider theme={themeCalculator(this.props._config)}>
            <CssBaseline />
            <UserLoggedInContextProvider value={this.state}>
              <Suspense
                fallback={
                  <Spinner
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                }
              >
                <Router basename={process.env.PUBLIC_URL}>
                  <Routes>
                    <Route
                      path={`/${this.props._config.printDetail}/:patientid`}
                      element={<PtPrintDetails />}
                    />
                    <Route
                      path={`/Summary_reportDetails/`}
                      element={<ReportsDetails />}
                    />
                    <Route path="provider/*" element={<Provider />} />
                    <Route path="coworker/*" element={<CoWorker />} />
                    <Route path="superadmin/*" element={<SuperAdmin />} />
                    <Route path="admin/*" element={<AccountAdmin />} />
                    <Route path="/claimadmin" element={<ClaimAdmin />} />
                    <Route path="guest/*" element={<PublicPage />}></Route>
                    <Route path={`/formReloader`} element={<FormReloader />} />
                    <Route path="firsttimelogin" element={<FirstTimeLogin />} />
                    <Route path="Signup" element={<SignUp />} />
                    <Route path="Unauthorized" element={<UnableToLogin />} />
                    <Route
                      path="avhana/prescribe/:token"
                      element={<Loader />}
                    />
                    <Route
                      path="avhana/showLogbook/:token"
                      element={<LoaderForLogbook />}
                    />
                    <Route
                      path={`/partnerService/launchCreatePatient/:patientSessionID/`}
                      element={<AuthSSO />}
                    />
                    <Route
                      path={`/partnerService/launchPatientLogbook/:hcpuserid/:userid/`}
                      element={<AuthSSO />}
                    />
                    <Route
                      path={`/partnerService/launchViewPatient/:hcpuserid/:userid/`}
                      element={<AuthSSO />}
                    />
                    <Route path="timeout" element={<TimeOutBackground />} />
                    <Route index element={<Navigate to="guest" />} />
                    <Route
                      path="SelectPreferences"
                      element={<SelectPreferences />}
                    />
                    <Route
                      path="updatedefaultconfig"
                      element={<UpdateDefaultConfig />}
                    />
                  </Routes>
                </Router>
              </Suspense>
            </UserLoggedInContextProvider>
          </ThemeProvider>
        </CacheProvider>
      </div>
    );
  }
}
App.propTypes = {
  t: PropTypes.object,
};
export default App;
