import axios from "axios";
import { API_MOUNT_PATH } from "../constants";
export default function getNewToken() {
  let refreshingPromise = undefined;
  return async function () {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      throw new Error("No refreshtoken");
    }
    if (!refreshingPromise) {
      refreshingPromise = axios({
        method: "post",
        url: API_MOUNT_PATH + "/providerauthservice/accessTokenByRefreshToken",
        data: { refreshToken },
      });
      let refreshResult;
      try {
        refreshResult = await refreshingPromise;
        if (refreshResult.status === 200) {
          localStorage.setItem("accessToken", refreshResult.data.accessToken);
          localStorage.setItem("refreshToken", refreshResult.data.refreshToken);
        }
      } finally {
        refreshingPromise = undefined;
      }
      return refreshResult;
    }
    return refreshingPromise;
  };
}
