import _ from "lodash";

export const getRoleBaseRedirectPath = (userRoleType) => {
  switch (userRoleType) {
    case "SuperAdmin":
      return "../../superadmin";
    case "AccountAdmin":
      return "../../admin";
    case "Provider":
      return "../../provider";
    case "CoWorker":
      return "../../coworker";
    case "DefaultAccountAdmin":
      return "../../admin";
    case "DoseCheckCampaignAdmin":
      return "../../claimadmin";
    default:
      break;
  }
};

export const getUserDetailObject = (data, t) => {
  let observationType;
  if (data.observationType !== undefined) {
    observationType = _.findLast(data.observationType, { type: 3 }).uom;
  }

  return {
    name: "abcd",
    isAuthenticated: true,
    role: data.userrole,
    permissionlevel: data.permissionlevel,
    firstname: data.firstname,
    lastname: data.lastname,
    country: data.country,
    onboarding: data.onboarding,
    language: data.language,
    glucoseUnit:
      observationType !== undefined && observationType[0] === 1
        ? t("commonCountrySpecific.COMMON__MG_DL")
        : t("commonCountrySpecific.COMMON__MMOL_L"),
    glucoseUom: observationType !== undefined && observationType[0],
    customerContact: data.customerContact,
    termsAndConditionVersion: data.termsAndConditionsVersion,
    isHeightWeightAvailableKey: data.isHeightWeightAvailableKey,
    isStudyAccAvailableKey: data.isStudyAccAvailableKey,
    userid: data.userid,
    emailid: data.emailid,
  };
};
