import React, { memo, useState, useEffect } from "react";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, InputAdornment, Typography } from "@mui/material";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import FormikTextField from "../../components/FormikValidatedComponents/TextField";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { withRouter } from "../../ContextAndHOC/WithRouter";
import { API_MOUNT_PATH } from "../../constants";
import { withMediaQuery } from "../../components/CustomInput/CustomMaterialUIWithWidth";
import Box from "@mui/material/Box";
import { CommonStyleObject } from "../../components/CSS/CommonCss";
import ResetPasswordStyleObject from "../../components/CSS/ResetPassWordStyle";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

const ForgetPassword = memo(function ForgetPassword(props) {
  const { theme, t } = props;
  const classes = {
    ...CommonStyleObject(theme),
    ...ResetPasswordStyleObject(theme),
  };
  const yupSchema = object().shape({
    email: string()
      .required(t("common.COMMON__FIELD_REQUIRED_MESSAGE"))
      .email(t("login.L__LOGIN__LOGIN_EMAIL_ERROR")),
  });
  const [stateForget, setStateForget] = useState({
    resetpasswordmsg: false,
    popUpMsg: "",
    invalidEmail: false,
  });
  useEffect(() => {
    if (props.location.state === "error") {
      setStateForget({
        ...stateForget,
        invalidEmail: true,
        popUpMsg: t("common.COMMON__MISSING_RESET_TOKEN"),
      });
    }
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    axios
      .post(API_MOUNT_PATH + "/providerauthservice/forgetPassword", {
        emailid: values.email,
      })
      .then((result) => {
        if (result.data.status === 1) {
          setStateForget({
            ...stateForget,
            resetpasswordmsg: true,
            invalidEmail: false,
            popUpMsg: props.t(
              "forgotPassword.F__FORGOT__WE_HAVE_SENT_A_RESET_LINK"
            ),
          });
          setSubmitting(false);
        } else if (result.data.status === 0) {
          setStateForget({
            ...stateForget,
            invalidEmail: true,
            popUpMsg: result.data.statusDescription,
          });
          setSubmitting(false);
        }
      })
      .catch((err) => {
        if (Object.assign({}, err.response).status === 422) {
          setStateForget({
            ...stateForget,
            invalidEmail: true,
            popUpMsg: props.t("login.L__EMAIL_NOT_REGISTERED"),
          });
        }
      });
  };
  let HeaderText = stateForget.popUpMsg;
  return (
    <>
      <Helmet>
        <title>{t("login.L__LOGIN_FORGET_TITLE_TEXT")}</title>
      </Helmet>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={handleSubmit}
        validationSchema={yupSchema}
      >
        {(props) => (
          <Form>
            <Box sx={classes.outSideLayout}>
              <Typography
                variant="h5"
                sx={[classes.setPasswordTitle, classes.forgetTitle]}
              >
                {t("forgotPassword.F__FORGOT__FOR_GOT_YOUR_PASSWORD")}
              </Typography>
              <Typography sx={[classes.setPasswordTitle, classes.dis]}>
                {t("forgotPassword.F__FORGOT__FOR_GOT_PASSWORD_MESSAGE")}
              </Typography>
              <Card sx={classes.cardShadow}>
                <CardBody sx={{ p: "24px" }}>
                  {stateForget.invalidEmail && (
                    <Alert severity="error" sx={classes.invalidtoken}>
                      {HeaderText}
                    </Alert>
                  )}
                  <GridContainer
                    sx={stateForget.invalidEmail ? { pt: "24px" } : null}
                  >
                    {!stateForget.resetpasswordmsg && (
                      <>
                        <GridItem sx={{ p: "0px" }}>
                          <Field
                            name="email"
                            fullWidth
                            label={t(
                              "forgotPassword.F__FORGOT__FOR_GOT_EMAIL_ADDRESS"
                            )}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    style={{
                                      color:
                                        theme.palette.text
                                          .textSecondaryColorNew,
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            component={FormikTextField}
                          />
                        </GridItem>
                        <GridItem sx={{ p: "0px" }}>
                          <Button
                            type="submit"
                            sx={[classes.primaryBtn, classes.btn]}
                            size="small"
                          >
                            {t(
                              "forgotPassword.F__FORGOT__FOR_GOT_CONFIRM_BUTTON"
                            )}
                          </Button>
                        </GridItem>
                      </>
                    )}
                    {stateForget.resetpasswordmsg && (
                      <Alert severity="success" sx={classes.success}>
                        {HeaderText}
                      </Alert>
                    )}
                    <GridItem sx={{ p: "0px" }}>
                      <Box
                        component={Link}
                        sx={classes.linkBtn}
                        to={"/guest/Login"}
                      >
                        {t("forgotPassword.F__FORGOT__FOR_GOT_BACK")}
                      </Box>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
});

export default withTranslation()(withMediaQuery()(withRouter(ForgetPassword)));
